import styled from "styled-components";
import { Fade } from "react-reveal";
import { Carousel } from "antd";
import { THEME_COLOR } from "../../utils/theme";
import data from "../../data/banner/banner.json";

const Banner = () => {
  return (
    <Fade>
      <section>
        <Carousel autoplay={true} effect="fade">
          {data.map(({ eventImage, title }, key) => (
            <CarouselContainer key={key} backgroundImg={"banner/" + eventImage}>
              <main>
                <h1>{title}</h1>
              </main>
            </CarouselContainer>
          ))}
        </Carousel>
      </section>
    </Fade>
  );
};

export default Banner;

const CarouselContainer = styled.div`
  height: 92vh;
  background-color: grey;
  ${({ backgroundImg }) => `background-image: url(${backgroundImg})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  > main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 92vh;
    margin: 0 25vw;
    background-color: rgba(19, 76, 156, 0.6);
    > img {
      margin: 10vh 1pc 1pc 1pc;
      border: 2px solid ${THEME_COLOR.YELLOW};
      object-fit: contain;
      height: 170px;
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding: 2pc;
    text-align: center;
    font-family: Julius Sans One;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 1000px) {
    > main {
      margin: 0 15vw;
      > img {
        height: 150px;
      }
    }
    h1 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    > main {
      margin: 0;
      > img {
        height: 120px;
      }
    }
    h1 {
      font-size: 1.5rem;
    }
  }
`;
