import { Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import KeyWord from "../KeyWord/KeyWord";
import { MdKeyboardArrowRight } from "react-icons/md";
import data from "../../data/products-and-services/training-development/training-development.json";

const TrainingDevelopment = () => {
  return (
    <Fade>
      <Container>
        <h2 style={{ color: THEME_COLOR.BLUE }}>Training & Development</h2>
        <section>
          <ul>
            {data.map((item, key) => (
              <li key={key}>
                <p>
                  <MdKeyboardArrowRight className="icon" />
                  <KeyWord word={item.title} theme={THEME_COLOR.BLUE} />
                </p>
                <p>{item.description}</p>
              </li>
            ))}
          </ul>
        </section>
      </Container>
    </Fade>
  );
};

export default TrainingDevelopment;

const Container = styled.div`
  padding: 2pc 4pc;
  width: 84vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  margin-top: 2pc;
  background-color: #fff;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    display: flex;
    align-items: center;
    .icon {
      display: none;
      color: ${THEME_COLOR.BLUE};
    }
  }
  .icon-special {
    display: none;
    color: ${THEME_COLOR.BLUE};
  }
  section {
    margin-top: 1pc;
    > ul {
      > li {
        font-size: large;
        text-align: justify;
        color: #3d3d3d;
        > p {
          :first-child {
            margin-bottom: 0.5pc;
          }
        }
        span {
          font-weight: medium;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 2pc 1pc;
    width: 95vw;
    h2,
    p {
      text-align: center;
      .icon {
        display: flex;
      }
    }
    .icon-special {
      display: flex;
      margin: 0.5pc;
    }
    li {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
`;
