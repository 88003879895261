import { useScrollSections } from "react-scroll-section";
import styled from "styled-components";

const AbsoluteLogo = () => {
  const logoSection = useScrollSections("logo");

  return (
    <Container>
      <img
        src="/images/logos/logo-1.jpg"
        alt=""
        onClick={logoSection.onClick}
        selected={logoSection.selected}
      />
    </Container>
  );
};

export default AbsoluteLogo;

const Container = styled.div`
  position: fixed;
  top: 10vh;
  right: 1pc;
  transition: 0.3s;
  z-index: 999;
  opacity: 0.8;
  > img {
    object-fit: contain;
    border-radius: 1rem;
    height: 5vw;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  :hover {
    opacity: 0.9;
    transition: 0.3s;
  }
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 15px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
