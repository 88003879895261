import { Bounce } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import KeyWord from "../KeyWord/KeyWord";
import BusinessConsultancy from "./BusinessConsultancy";
import DigitalOfferings from "./DigitalOfferings";
import SafetyProducts from "./SafetyProducts";
import TrainingDevelopment from "./TrainingDevelopment";

const ProductsServices = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Our <KeyWord theme={THEME_COLOR.YELLOW} word="Products" /> and 
          <KeyWord theme={THEME_COLOR.YELLOW} word=" Services" />
        </h1>
      </Bounce>
      <section>
        <TrainingDevelopment />
        <main>
          <BusinessConsultancy />
          <SafetyProducts />
        </main>

        <DigitalOfferings />
      </section>
    </Container>
  );
};

export default ProductsServices;

const Container = styled.div`
  padding: 4pc 3pc;
  background-color: #08244c;

  > h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    color: white;
  }
  > section {
    margin-top: 2pc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 3pc 0pc;
    > h1 {
      font-size: 1.6rem;
    }
    > section {
      margin-top: 0;

      > main {
        flex-direction: column;
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style-type: none;
      text-align: center;
      font-size: medium;
      padding: 0.5pc;
    }
  }
`;
