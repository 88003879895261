import { Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import KeyWord from "../KeyWord/KeyWord";

const DigitalOfferings = () => {
  return (
    <Fade>
      <Container>
        <h2 style={{ color: THEME_COLOR.BLUE }}>Digital Offerings</h2>
        <section>
          <img src="images/pns/1.jpg" alt="" />
          <p>
            <KeyWord word="BAS" theme={THEME_COLOR.BLUE} /> is offering
            solutions for the clients and training for employees to connect and
            work digitally. We lead the transformation of this digital work
            spaces and engage employees. This ensures better motivated and
            connected work force
          </p>
        </section>
      </Container>
    </Fade>
  );
};

export default DigitalOfferings;

const Container = styled.div`
  padding: 2pc 4pc;
  width: 84vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  margin-top: 2pc;
  background-color: #fff;

  > h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  > section {
    display: flex;
    align-items: center;
    margin-top: 1pc;
    > p {
      font-size: large;
      text-align: justify;
      color: #3d3d3d;
    }
    > img {
      object-fit: contain;
      height: 150px;
      margin-top: 0.5pc;
      margin-right: 2pc;
      -webkit-animation: rotate 20s normal linear infinite;
      animation: rotate 20s normal linear infinite;
    }
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
      transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
      -webkit-transform: rotate3d(0, 0, 1, 180deg);
      transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
      -webkit-transform: rotate3d(0, 0, 1, 270deg);
      transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
  @media screen and (max-width: 600px) {
    padding: 2pc 1pc;
    width: 95vw;
    h2 {
      text-align: center;
    }
    > section {
      flex-direction: column;
      > img {
        margin: 1.5pc;
      }
    }
  }
`;
