import {
  FacebookFilled,
  LinkedinFilled,
  YoutubeFilled,
  InstagramFilled,
} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import SocialMedia from "../SocialMedia/SocialMedia";
import { THEME_COLOR } from "../../utils/theme";
import { SOCIAL_MEDIA_LINKS } from "../../utils/constants";

const Footer = () => {
  return (
    <Fade>
      <Container>
        <main>
          <section>
            <p>Follow us on</p>
            <main>
              <SocialMedia
                Icon={<FacebookFilled />}
                link={SOCIAL_MEDIA_LINKS.FACEBOOK_URL}
                title="facebook"
              />
              <SocialMedia
                Icon={<InstagramFilled />}
                link={SOCIAL_MEDIA_LINKS.INSTAGRAM_URL}
                title="instagram"
              />
              <SocialMedia
                Icon={<YoutubeFilled />}
                link={SOCIAL_MEDIA_LINKS.YOUTUBE_URL}
                title="youtube"
              />
              <SocialMedia
                Icon={<LinkedinFilled />}
                link={SOCIAL_MEDIA_LINKS.LINKED_IN_URL}
                title="linkedin"
              />
            </main>
          </section>
          <section>
            <img src="/images/logos/logo-1.jpg" alt="" />
          </section>
          <section>
            <p>
              <strong>Email:</strong>{" "}
              <a
                href="mailto:hello@bastrio.com"
                target="_blank"
                rel="noreferrer">
                hello@bastrio.com
              </a>
            </p>
            <p>
              <strong>Tel:</strong>{" "}
              <a href="tel:+94774056453">+94 77 405 6453</a>
            </p>
          </section>
        </main>
        <main>
          <p className="footer__bastrioOfficial">
            <p>BAS Holdings Lanka (Private) Limited</p>
            <p>COMPANY REGISTRATION NO: PV00220614</p>
          </p>
        </main>
      </Container>
    </Fade>
  );
};

export default Footer;

const Container = styled.div`
  padding: 3pc;
  display: flex;
  background-color: #08244c;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: space-between;
  > main {
    .footer__bastrioOfficial {
      margin-bottom: -3pc;
      padding-top: 2pc;
      padding-bottom: 0.5pc;
      width: 100%;
      > p {
        text-align: center;
        font-weight: lighter;
        opacity: 0.2;
        color: white !important;
        padding: 0;
        margin: 0;
      }
    }
    display: flex;
    > section {
      flex: 1;

      display: flex;
      flex-direction: column;
      :nth-child(2) {
        align-items: center;
      }
      :last-child {
        align-items: flex-end;
      }
    }
    p {
      font-weight: bold;
      color: gold;
      > a {
        font-weight: bold;
        margin: 10px 0;
        color: gold;
        cursor: pointer;
      }
    }
    img {
      border: 2px solid ${THEME_COLOR.YELLOW};
      object-fit: contain;
      height: 130px;
      width: fit-content;
      border-radius: 2.5rem;
    }
    :last-child {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 500px) {
    padding-top: 2pc;
    > main {
      .footer__bastrioOfficial {
        > p {
          font-size: smaller;
        }
      }
      :first-child {
        flex-direction: column;
        justify-content: center;
        img {
          object-fit: contain;
          height: 80px;
          border: none;
        }
        section {
          :first-child {
            > main {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          :last-child {
            align-items: center;
          }
        }
      }

      p {
        text-align: center;
        :first-child {
          padding-top: 8px;
        }
      }
    }
  }
`;
