import { Bounce, Fade } from "react-reveal";
import styled from "styled-components";
import { SOCIAL_MEDIA_LINKS } from "../../utils/constants";
import { THEME_COLOR } from "../../utils/theme";
import KeyWord from "../KeyWord/KeyWord";

const CorporateVideo = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          <span style={{ color: THEME_COLOR.BLUE }}>Logo</span> Reveal
        </h1>
      </Bounce>
      <Fade>
        <iframe
          width="560"
          height="480"
          src="https://www.youtube.com/embed/SWAuvtJ3P7E"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </Fade>
      <p>
        Click{" "}
        <a href={SOCIAL_MEDIA_LINKS.YOUTUBE_URL} rel="noreferrer" target="_blank">
          <KeyWord word="here" theme={THEME_COLOR.BLUE} />
        </a>{" "}
        to visit the playlist
      </p>
    </Container>
  );
};

export default CorporateVideo;

const Container = styled.div`
  padding: 5pc 3pc;
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: ${THEME_COLOR.YELLOW};
    text-transform: uppercase;
  }
  p {
    margin-top: 1pc;
    font-size: medium;
  }
  iframe {
    overflow: hidden;
    width: 100%;
    margin-top: 1pc;
  }
  @media screen and (max-width: 500px) {
    padding: 2pc 0pc;
    p {
      text-align: center;
    }
    h1 {
      padding: 1pc;
      font-size: 1.8rem;
    }
    iframe {
      margin-top: 0;
      height: 400px;
    }
  }
`;
