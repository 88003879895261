import styled from "styled-components";

const SocialMedia = ({ Icon, title, link }) => {
  return (
    <Container>
      <a href={link} rel="noreferrer" target="_blank">
        <div>
          <span>{Icon}</span>
          <span>{title}</span>
        </div>
      </a>
    </Container>
  );
};

export default SocialMedia;

const Container = styled.div`
  a {
    color: gold;
    > div {
      display: flex;
      align-items: center;
      > span {
        font-size: small;
        font-weight: bold;
        margin: 5px;
        > span {
          font-size: large;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    a {
      > div {
        > span:last-child {
          display: none;
        }
      }
    }
  }
`;
