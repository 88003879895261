import { Bounce } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import PastEventItem from "./PastEventItem";
import data from "../../data/events/pastevents/pastevents.json";

const PastEvents = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Past <span style={{ color: THEME_COLOR.YELLOW }}>Events</span>
        </h1>
      </Bounce>

      <section>
        {data.map(
          (
            {
              title,
              eventImage,
              description,
              leadName,
              designation,
              brandName,
            },
            key
          ) => (
            <PastEventItem
              title={title}
              image={"past-events/" + eventImage}
              description={description}
              leadName={leadName}
              designation={designation}
              brandName={brandName}
              key={key}
            />
          )
        )}
      </section>
    </Container>
  );
};

export default PastEvents;

const Container = styled.div`
  padding: 4pc 3pc;
  background-color: ${THEME_COLOR.BLUE};

  > h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  section {
    margin: 3pc 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 600px) {
    padding: 3pc .5pc;
    > h1 {
      font-size: 1.8rem;
    }
    section {
      margin: 0;
      flex-direction: column;
    }
  }
`;
