import styled from "styled-components";
import AboutUs from "../components/AboutUs/AboutUs";
import Banner from "../components/Banner/Banner";
import Clients from "../components/Clients/Clients";
import CorporateVideo from "../components/CorporateVideo/CorporateVideo";
import Footer from "../components/Footer/Footer";
import Gallery from "../components/Gallery/Gallery";
import Highlights from "../components/Highlights/Highlights";
import Message from "../components/Message/Message";
import PastEvents from "../components/PastEvents/PastEvents";
import ProductsServices from "../components/ProductsServices/ProductsServices";
import { ScrollingProvider, Section } from "react-scroll-section";
import Header from "../components/Header/Header";
import DrawableMenu from "../components/Header/DrawableMenu/DrawableMenu";
import ContactUs from "../components/ContactUs/ContactUs";
import UpComingEvents from "../components/UpComingEvents/UpComingEvents";
import AbsoluteLogo from "../components/AbsoluteLogo/AbsoluteLogo";

const Home = () => {
  return (
    <Container>
      <ScrollingProvider>
        <Header />
        <DrawableMenu />
        <AbsoluteLogo />
        <Banner />
        <Highlights />

        <Section id="logo">
          <CorporateVideo />
        </Section>

        <Section id="about">
          <AboutUs />
        </Section>

        <Section id="message">
          <Message />
        </Section>

        <Section id="productService">
          <ProductsServices />
        </Section>

        <Section id="clients">
          <Clients />
        </Section>

        <Section id="events">
          <UpComingEvents />
        </Section>

        <PastEvents />

        <Section id="gallery">
          <Gallery />
        </Section>

        <Section id="contactUs">
          <ContactUs />
        </Section>

        <Footer />
      </ScrollingProvider>
    </Container>
  );
};

export default Home;

const Container = styled.div``;
