import { Bounce } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import data from "../../data/events/upcoming-events/upcoming-events.json";

const UpComingEvents = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Upcoming <span style={{ color: THEME_COLOR.YELLOW }}>Events</span>
        </h1>
      </Bounce>
      {data.map(({ eventImage }, key) => (
        <img src={"upcoming-events/" + eventImage} alt="" key={key} />
      ))}
    </Container>
  );
};

export default UpComingEvents;

const Container = styled.div`
  padding: 4pc 3pc;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${THEME_COLOR.BLUE};

  > h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
  > img {
    margin: 1pc 0;
    object-fit: contain;
    width: 60vw;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 10px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
  @media screen and (max-width: 600px) {
    padding: 3pc 0.5pc;
    > h1 {
      font-size: 1.8rem;
    }
    > img {
      margin: 0;
      width: 95vw;
    }
  }
`;
