import { Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import Marquee from "react-fast-marquee";

const ClientView = ({ items, direction, isImages }) => {
  return (
    <Fade>
      <Container isImages={isImages}>
        <Marquee
          className="marquee__section"
          speed={isImages ? 60 : 50}
          direction={direction}>
          {isImages
            ? items.map(({ image }, key) => (
                <img src={"/clients/logos/" + image} key={key} alt="client" />
              ))
            : items.map(({ title }, key) => (
                <p style={{ color: THEME_COLOR.BLUE }} key={key}>
                  {title}
                </p>
              ))}
        </Marquee>
      </Container>
    </Fade>
  );
};

export default ClientView;

const Container = styled.div`
  margin: ${({ isImages }) => (isImages ? "2pc" : "0")} -3pc;
  background-color: ${({ isImages }) =>
    isImages ? "white" : `${THEME_COLOR.BLUE}`};
  padding: ${({ isImages }) => (isImages ? "1pc" : "0")} 3pc;
  height: ${({ isImages }) => (isImages ? "30vh" : "15vh")};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  .marquee__section {
    .overlay {
      display: ${({ isImages }) => !isImages && "none"};
    }
  }
  p {
    font-size: 1.5rem;
    color: white !important;
    width: 50vw;
    margin-top: ${({ isImages }) => !isImages && "1.5pc"};
    text-align: center;
  }
  img {
    object-fit: contain;
    width: 100px;
    margin: 0 3vw;
  }
  @media screen and (max-width: 1000px) {
    height: ${({ isImages }) => (isImages ? "200px" : "100px")};
    img {
      width: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 1pc 0;
    overflow: hidden !important;
    padding: 1pc;
    flex-direction: column;
    height: ${({ isImages }) => (isImages ? "200px" : "80px")};
    justify-content: center;
    .marquee__section {
      width: 150%;
    }
    p {
      font-size: 1rem;
      margin-top: ${({ isImages }) => !isImages && "1pc"};
      width: 100vw;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;
