import { Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";

const Highlights = () => {
  return (
    <Fade>
      <Container>
        <img src="images/logos/logo-1.jpg" alt="" />
      </Container>
    </Fade>
  );
};

export default Highlights;

const Container = styled.div`
  background-image: linear-gradient(
    to top,
    #ffffff,
    #dfe1f6,
    #b5c7ed,
    #7eafe4,
    #129ad8
  );
  padding: 2pc;
  display: grid;
  place-items: center;
  > img {
    border: 2px solid ${THEME_COLOR.YELLOW};
    object-fit: contain;
    border-radius: 2.5rem;
    height: 10vw;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
