import styled from "styled-components";
import { Fade, Bounce } from "react-reveal";
import { MdEmail, MdCall } from "react-icons/md";
import { THEME_COLOR } from "../../utils/theme";

const ContactUs = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Contact <span style={{ color: THEME_COLOR.BLUE }}>Us</span>
        </h1>
      </Bounce>
      <Fade>
        <main>
          <a href="mailto:hello@bastrio.com">
            <section>
              <MdEmail className="contact__icon" />
              <h1>Contact by Email</h1>
              <p>hello@bastrio.com</p>
              <p>Click to compose a mail</p>
            </section>
          </a>
          <a href="tel:+94774056453">
            <section>
              <MdCall className="contact__icon" />
              <h1>Make a Call Now</h1>
              <p>+94 77 405 6453</p>
              <p>Click to make a call</p>
            </section>
          </a>
        </main>
      </Fade>
    </Container>
  );
};

export default ContactUs;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${THEME_COLOR.YELLOW};
  padding: 5pc 3pc;
  > h1 {
    font-size: 2.5rem;
    text-align: center;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
  }
  > main {
    display: flex;
    justify-content: center;
    section {
      display: flex;
      flex-direction: column;
      background-color: whitesmoke;
      margin: 2pc;
      padding: 2pc 3pc;
      width: 40vw;
      align-items: center;
      .contact__icon {
        font-size: 2.5rem;
        color: ${THEME_COLOR.BLUE};
        margin: 1pc;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
      }
      a {
        color: ${THEME_COLOR.BLUE};
        font-size: medium;
      }
      h1 {
        font-weight: bold;
      }
      p,
      h1 {
        color: #444444;
        text-align: center;
      }
      p {
        font-size: medium;
        line-height: 1pc;
      }
      cursor: pointer;
      .contact__icon {
        cursor: pointer;
        animation-name: bounce;
        -moz-animation-name: bounce;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 2pc 1pc;
    > h1 {
      padding: 1pc;
      font-size: 1.8rem;
    }
    > main {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      section {
        width: 90vw;
        margin: 1pc 0;
      }
    }
  }
  @keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
`;
