import styled from "styled-components";
import { Fade } from "react-reveal";
import { useScrollSection } from "react-scroll-section";
import { THEME_COLOR } from "../../utils/theme";

const Header = () => {
  const logoSection = useScrollSection("logo");
  const aboutSection = useScrollSection("about");
  const messageSection = useScrollSection("message");
  const productServiceSection = useScrollSection("productService");
  const clientsSection = useScrollSection("clients");
  const eventsSection = useScrollSection("events");
  const gallerySection = useScrollSection("gallery");
  const contactUsSection = useScrollSection("contactUs");

  return (
    <Container>
      <Fade>
        <section>
          <nav className="topBottomBordersIn">
            <li onClick={logoSection.onClick} selected={logoSection.selected}>
              Home
            </li>
            <li onClick={aboutSection.onClick} selected={aboutSection.selected}>
              About
            </li>
            <li
              onClick={messageSection.onClick}
              selected={messageSection.selected}>
              Message
            </li>
            <li
              onClick={productServiceSection.onClick}
              selected={productServiceSection.selected}>
              Products & Services
            </li>
            <li
              onClick={clientsSection.onClick}
              selected={clientsSection.selected}>
              Clients
            </li>
            <li
              onClick={eventsSection.onClick}
              selected={eventsSection.selected}>
              Events
            </li>
            <li
              onClick={gallerySection.onClick}
              selected={gallerySection.selected}>
              Gallery
            </li>
            <li
              onClick={contactUsSection.onClick}
              selected={contactUsSection.selected}>
              Contact Us
            </li>
          </nav>
        </section>
      </Fade>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 999 !important;
  background-color: #08244c;
  cursor: pointer;
  -webkit-box-shadow: 0 6px 6px -6px black;
  -moz-box-shadow: 0 6px 6px -6px black;
  box-shadow: 0 6px 6px -6px black;
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > nav {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        list-style-type: none;
        text-align: center;
        padding: 0 1pc;
        color: ${THEME_COLOR.YELLOW};
        cursor: pointer;
        font-weight: normal;
        font-size: large;
        text-transform: capitalize !important;
        transition: 0.1s ease-in-out;
        :hover {
          transform: scale(1.05);
          transition: 0.1s ease-in-out;
        }
      }
      ul {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
