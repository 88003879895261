import { Bounce, Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";

const AboutUs = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          About <span style={{ color: "white" }}>Us</span>
        </h1>
      </Bounce>
      <section>
        <Fade>
          <div>
            <h2 style={{ color: THEME_COLOR.YELLOW }}>Our Philosophy</h2>
            <p>
              “We believe a business is only as strong as its people. Therefore,
              responsive, customized, need based training makes the difference
              between a good workforce and a great one. We ensure that employee
              motivation & development will drive your organization to new
              heights.”
            </p>
          </div>
        </Fade>
        <Fade>
          <div>
            <h2 style={{ color: THEME_COLOR.YELLOW }}>Vision</h2>
            <p>
              Making each individual to reach the Best Attainable Step in life
            </p>
          </div>
        </Fade>
        <Fade>
          <div>
            <h2 style={{ color: THEME_COLOR.YELLOW }}>Mission</h2>
            <ul>
              <li>Unleashing the power and purpose of people</li>
              <li>Grow next level leaders by training the trainers</li>
              <li>Drive agile mindset to best suit the dynamic environment</li>
              <li>
                Groom a multi talented individual through proper coaching and
                mentoring
              </li>
            </ul>
          </div>
        </Fade>
        <Fade>
          <div>
            <br/>
            <h2 style={{ color: THEME_COLOR.YELLOW }}>Trio Energy</h2>
            <ul>
              <li>
                <span style={{ color: THEME_COLOR.YELLOW }}>BAS</span> consist
                of 3 young individuals who collectively hold more than 4 decades
                of multinational exposure.
              </li>
              <li>
                The 3 of us are coming with a diverse exposure from Management,
                Marketing, Sales, Agriculture, Food Science & Information
                Technology.
              </li>
              <li>
                This indispensable combination of the trio has open paths to
                multiple levels of exposure & experience which we are willing to
                share with individuals in order to make their{" "}
                <span style={{ color: THEME_COLOR.YELLOW }}>
                  "Best Attainable Step"
                </span>
                .
              </li>
            </ul>
          </div>
        </Fade>
      </section>
    </Container>
  );
};

export default AboutUs;

const Container = styled.div`
  background-color: ${THEME_COLOR.BLUE};
  padding: 3pc;
  > h1 {
    font-size: 2.5rem;
    text-align: center;
    color: ${THEME_COLOR.YELLOW};
    font-weight: bold;
    text-transform: uppercase;
  }
  section {
    > div {
      padding: 1pc 0;
      > h2 {
        font-size: 1.5rem;
        font-weight: bold;
      }
      > p,
      li {
        font-size: large;
        text-align: justify;
        color: white;
      }
    }
  }

  @media screen and (max-width: 500px) {
    padding: 3pc 1pc;

    > h1 {
      font-size: 1.8rem;
    }
    section {
      > div {
        padding: 0.5pc 0;

        > h2 {
          text-align: center;
          font-size: 1.3rem;
        }
        > p {
          text-align: justify;
        }
        li {
          list-style-type: none;
          text-align: justify;
          padding: 0pc;
        }
        ul {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
`;
