import { Menu, Drawer, Button, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useState } from "react";
import styled from "styled-components";
import { useScrollSection } from "react-scroll-section";
import { THEME_COLOR } from "../../../utils/theme";

const DrawableMenu = () => {
  const [visible, setVisible] = useState(false);
  const [placement] = useState("left");
  const logoSection = useScrollSection("logo");
  const aboutSection = useScrollSection("about");
  const messageSection = useScrollSection("message");
  const productServiceSection = useScrollSection("productService");
  const clientsSection = useScrollSection("clients");
  const eventsSection = useScrollSection("events");
  const gallerySection = useScrollSection("gallery");
  const contactUsSection = useScrollSection("contactUs");
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <ContainerMenu>
      <Space className="drawableMenu__space">
        <Button className="menuButton" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
        <h2>BAS</h2>
        <img src="/images/logos/logo-1.jpg" alt="" className="logo__image" />
      </Space>
      <Drawer
        title="BASTRIO"
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible}
        key={placement}>
        <Menu style={{ width: 250, marginLeft: -20 }}>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="1"
            onClick={() => {
              logoSection.onClick();
              onClose();
            }}
            selected={logoSection.selected}
            className="menuItem">
            LOGO
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="2"
            onClick={() => {
              aboutSection.onClick();
              onClose();
            }}
            selected={aboutSection.selected}
            className="menuItem">
            ABOUT
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="3"
            onClick={() => {
              messageSection.onClick();
              onClose();
            }}
            selected={messageSection.selected}
            className="menuItem">
            MESSAGE
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="4"
            onClick={() => {
              productServiceSection.onClick();
              onClose();
            }}
            selected={productServiceSection.selected}
            className="menuItem">
            PRODUCTS & SERVICES
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="5"
            onClick={() => {
              clientsSection.onClick();
              onClose();
            }}
            selected={clientsSection.selected}
            className="menuItem">
            CLIENTS
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="6"
            onClick={() => {
              eventsSection.onClick();
              onClose();
            }}
            selected={eventsSection.selected}
            className="menuItem">
            EVENTS
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="7"
            onClick={() => {
              gallerySection.onClick();
              onClose();
            }}
            selected={gallerySection.selected}
            className="menuItem">
            GALLERY
          </Menu.Item>
          <Menu.Item
            style={{ color: `${THEME_COLOR.BLUE}`, fontSize: "small" }}
            key="8"
            onClick={() => {
              contactUsSection.onClick();
              onClose();
            }}
            selected={contactUsSection.selected}
            className="menuItem">
            CONTACT US
          </Menu.Item>
        </Menu>
      </Drawer>
    </ContainerMenu>
  );
};

export default DrawableMenu;

const ContainerMenu = styled.div`
  position: sticky;
  top: 0;
  z-index: 999 !important;
  background-color: #08244c;
  font-family: Julius Sans One;
  .drawableMenu__space {
    display: none;
    justify-content: space-between;
  }
  img {
    border-radius: 2rem;
  }
  .menuItem {
    font-size: small;
  }
  .menuButton {
    margin-left: 10px;
    background-color: #08244c;
    border-color: gold;
    * {
      color: gold;
    }
  }
  .logo__image {
    margin-right: 10px !important;
    object-fit: contain;
    height: 40px;
  }
  @media screen and (max-width: 800px) {
    .drawableMenu__space {
      height: 50px;
      display: flex;
      align-items: center;
      h2 {
        height: 100%;
        color: gold;
        margin-top: 1pc;
        font-family: Constantia !important;
        font-size: 2rem;
        /* font-weight: bold; */
      }
    }
  }
`;
