import { Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import data from "../../data/products-and-services/safety-products/safety-products.json";

const SafetyProducts = () => {
  return (
    <Fade>
      <Container>
        <h2 style={{ color: THEME_COLOR.BLUE }}>Safety Products</h2>
        <section>
          <ul>
            {data.map((item, key) => (
              <li key={key}>
                <p>{item.title}</p>
              </li>
            ))}
          </ul>
        </section>
      </Container>
    </Fade>
  );
};

export default SafetyProducts;

const Container = styled.div`
  padding: 1pc 2pc;
  width: 40vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  margin-top: 2pc;
  height: 450px;
  background-color: #fff;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
  }
  section {
    margin-top: 1pc;
    li {
      font-size: large;
      text-align: justify;
      color: #3d3d3d;
      span {
        font-weight: medium;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    p {
      text-align: center;
      :first-child {
        margin-bottom: 0.5pc;
      }
    }
  }
`;
