import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import Loading from "./pages/Loading";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/">{loading ? <Loading /> : <Home />}</Route>
      </Switch>
    </Router>
  );
};

export default App;
