import React from "react";

const KeyWord = ({ word, theme }) => {
  return (
    <span style={{ color: theme }}>
      <strong>{word}</strong>
    </span>
  );
};

export default KeyWord;
