import { Bounce, Fade } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import KeyWord from "../KeyWord/KeyWord";

const Message = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Message from the{" "}
          <span style={{ color: THEME_COLOR.BLUE }}>Marketing Director</span>
        </h1>
      </Bounce>
      <Fade>
        <section>
          <Fade>
            <img src="images/aboutus/1.jpeg" alt="" />
          </Fade>
          <main>
            <Fade>
              <p>
                “It is the{" "}
                <KeyWord
                  word="mindset, attitude & skills"
                  theme={THEME_COLOR.BLUE}
                />{" "}
                of each and every leader in an Organization builds the
                Organization in comparison to the other resources in a business”
              </p>

              <p>
                “Throughout{" "}
                <KeyWord
                  word="my professional Career as Director Marketing"
                  theme={THEME_COLOR.BLUE}
                />{" "}
                and as{" "}
                <KeyWord word="Professional Trainer" theme={THEME_COLOR.BLUE} />{" "}
                I have witnessed the Performance & behavioural gaps of employees
                as a cause of low motivation and poor leadership skills. This
                untapped potential of employees has lead to failure of{" "}
                <KeyWord word="businesses" theme={THEME_COLOR.BLUE} />. As a
                proven solution, training and development plays a paramount
                importance in mitigating the skill and behavioural gaps found
                among the employees.”
              </p>

              <p>
                We from <KeyWord word="BAS" theme={THEME_COLOR.BLUE} /> provide
                an unmatchable advantage through our{" "}
                <KeyWord word="TRIO Combination" theme={THEME_COLOR.BLUE} />. We
                as a training team of 3, have more than{" "}
                <KeyWord
                  word="40 years of collective
                Professional experience"
                  theme={THEME_COLOR.BLUE}
                />
                . We have provided proven solutions to our customers in the past
                through our tailor made training programs.
              </p>
              <p>
                The <KeyWord word="TRIO Combination" theme={THEME_COLOR.BLUE} />{" "}
                has transformed hundreds of Professionals to their next level.{" "}
              </p>

              <p>
                Now it’s your time to take the{" "}
                <KeyWord word="Best Attainable Step" theme={THEME_COLOR.BLUE} />{" "}
                for you and for your employees.
              </p>
              <img src="images/sign/sign.jpg" alt="" />
              <p className="message__final">
                <p>R.S.Seelan</p>
                <p style={{ fontSize: "small", color: "grey" }}>
                  BSc(UOM), MBA(UK), SCJP
                </p>
              </p>
              <p className="message__final" style={{ fontSize: "small" }}>
                <p>Marketing Director,</p>
                <p>BAS</p>
              </p>
            </Fade>
          </main>
        </section>
      </Fade>
    </Container>
  );
};

export default Message;

const Container = styled.div`
  padding: 4pc 3pc;
  > h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    color: ${THEME_COLOR.YELLOW};
  }
  .message__final {
    line-height: 0.5pc;
  }
  section {
    display: flex;
    padding: 3pc 0;
    > img {
      object-fit: contain;
      height: 450px;
    }
    > main {
      margin: 0 2pc;
      > p {
        font-size: large;
        color: #3d3d3d;
        text-align: justify;
      }
      > img {
        object-fit: contain;
        height: 45px;
        border-radius: 100px;
        margin: 1.5pc 0;
      }
    }
  }
  @media screen and (max-width: 800px) {
    padding: 3pc 0.5pc;

    > h1 {
      font-size: 1.6rem;
    }
    section {
      padding: 1pc 0;
      flex-direction: column;
      > img {
        height: 280px;
        border: none !important;
      }
      > main {
        margin: 2pc 0.5pc;
      }
    }
  }
`;
