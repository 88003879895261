import styled from "styled-components";
import ReactLoading from "react-loading";
import { THEME_COLOR } from "../utils/theme";
import { Bounce } from "react-reveal";
const Loading = () => {
  return (
    <Container>
      <Bounce>
        <div>
          <img src="images/logos/logo-1.jpg" alt="" />
          <ReactLoading
            color={THEME_COLOR.YELLOW}
            type="bars"
            className="loading__spinner"
            height={"10%"}
            width={"10%"}
          />
        </div>
      </Bounce>
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  background-color: ${THEME_COLOR.BLUE};
  height: 100vh;
  overflow: hidden;

  > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding-top: 25vh;

    img {
      object-fit: contain;
      height: 14vw;
      border: 2px solid ${THEME_COLOR.YELLOW};
      border-radius: 2.5rem;
    }
  }
  @media screen and (max-width: 600px) {
    > div {
      img {
        height: 150px;
      }
      .loading__spinner {
        height: 100px !important;
        width: 100px !important;
      }
    }
  }
`;
