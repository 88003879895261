import { Zoom } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";

const PastEventItem = ({
  title,
  image,
  description,
  leadName,
  designation,
  brandName,
}) => {
  return (
    <Zoom>
      <Container>
        <h2 style={{ color: THEME_COLOR.BLUE }}>{title}</h2>
        <img src={image} alt="" />
        <p>{description}</p>
        <div>
          <p>{leadName}</p>
          <p>{designation}</p>
          <p>{brandName}</p>
        </div>
      </Container>
    </Zoom>
  );
};

export default PastEventItem;

const Container = styled.div`
  width: 40vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  margin: 1pc 0;
  height: 45pc;
  padding: 3pc 1pc;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > img {
    object-fit: contain;
    width: 23vw;
    margin: 1pc;
  }
  > h2 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
  }
  > p {
    text-align: center;
    font-size: medium;
    margin: 2pc 1pc;
    text-justify: justify;
  }
  > div {
    width: 90%;
    > p {
      font-size: small;
      margin: 0;
      text-align: end;
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 2pc 0.5pc;
    width: 95vw;
    height: auto;
    > img {
      width: 200px;
    }
    > p {
      text-align: justify;
    }
    > h2 {
      font-size: 1.3rem;
    }
  }
`;
