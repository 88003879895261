import { Bounce } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import ClientView from "./ClientView";
import clientTitles from "../../data/clients/titles/clientTitles.json";
import clientLogos from "../../data/clients/logos/clientLogos.json";

const Clients = () => {

  return (
    <Container>
      <Bounce>
        <h1>
          Our <span style={{ color: "BLACK" }}>Clients</span>
        </h1>
      </Bounce>

      <section>
        <ClientView
          items={clientTitles}
          direction="right"
          isImages={false}
        />
        <ClientView
          items={clientLogos}
          direction="right"
          isImages={true}
        />
      </section>
    </Container>
  );
};

export default Clients;

const Container = styled.div`
  padding: 4pc 3pc;
  background-color: ${THEME_COLOR.YELLOW};
  > h1 {
    font-size: 2.3rem;
    font-weight: bold;
    text-align: center;
    color: ${THEME_COLOR.BLUE};
  }
  section {
    margin: 3pc 0;
  }
  @media screen and (max-width: 600px) {
    padding: 3pc 0pc;
    > h1 {
      font-size: 1.5rem;
    }
    section {
      margin: 2pc 0;
    }
  }
`;
