import { Bounce, Zoom } from "react-reveal";
import styled from "styled-components";
import { THEME_COLOR } from "../../utils/theme";
import galleryImage from "../../data/gallery/gallery.json";

const Gallery = () => {
  return (
    <Container>
      <Bounce>
        <h1>
          Our <span style={{ color: THEME_COLOR.BLUE }}>Gallery</span>
        </h1>
      </Bounce>
      <section>
        {galleryImage.map((image, key) => (
          <Zoom>
            <div>
              <img src={"gallery/" + image.eventImage} key={key} alt="" />
            </div>
          </Zoom>
        ))}
      </section>
    </Container>
  );
};

export default Gallery;

const Container = styled.div`
  padding: 4pc 3pc;
  > h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: ${THEME_COLOR.YELLOW};
  }
  > section {
    margin: 3pc 1pc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    > div {
      overflow: hidden;
      /* border: 2px solid ${THEME_COLOR.BLUE}; */
      margin: 1pc;
      > img {
        object-fit: contain;
        background-color: white;
        width: 350px;
        cursor: pointer;
        max-width: 100%;
        -webkit-transition: all 0.7s ease;
        transition: all 0.7s ease;
        :hover {
          -webkit-transform: scale(1.2);
          transform: scale(1.1);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 3rem 0rem;

    > h1 {
      font-size: 1.8rem;
    }
    > section {
      margin: .5pc;

      > img {
        width: 90vw !important;
      }
    }
  }
`;
